// i18next-extract-mark-ns-start cofidis

import {Content} from 'components/Content';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';

import cofidis from 'images/4xcard_cofidis_logo.svg';
import step4 from 'images/cofidis_approval.svg';
import step1 from 'images/cofidis_cart.svg';
import step2 from 'images/cofidis_payment_method.svg';
import step3 from 'images/cofidis_request.svg';
import React from 'react';
import styled from 'styled-components';

const Logo = styled.div`
  text-align: center;
  margin: 80px 0 30px;
`;

const Subheader = styled.p`
  text-align: center;
`;

const Steps = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

const Step = styled.div`
  width: 25%;
  margin: 30px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 30px 0;
  }
`;

const StepImage = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
`;

const StepCaption = styled.div`
  text-align: center;
`;

const Details = styled.div`
  margin: 0 auto 60px;
  max-width: 740px;
`;

const Legal = styled.div`
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: hsla(0, 0%, 10%, 0.5);
`;

const Page: React.FC = () => {
  return (
    <>
      <SEO
        path="cofidis"
        title="Cofidis"
        description="Una forma fácil y rápida de financiar tu pago con Cofidis en 4 cuotas /90 días con cargo a tu tarjeta."
      />
      <Content>
        <Logo>
          <img src={cofidis} alt="4xcard by Cofidis" width={200} />
        </Logo>
        <Subheader>
          Una forma fácil y rápida de financiar tu pago con Cofidis en 4 cuotas / 90 días con cargo
          a tu tarjeta*.
        </Subheader>
        <Steps>
          <Step>
            <StepImage>
              <img src={step1} alt="Cofidis Carrito" />
            </StepImage>
            <StepCaption>Añade productos al carrito, para compras entre 75€ y 1.000€</StepCaption>
          </Step>
          <Step>
            <StepImage>
              <img src={step2} alt="Cofidis Método de pago" />
            </StepImage>
            <StepCaption>Elige “4xcard” como método de pago para financiar tu compra</StepCaption>
          </Step>
          <Step>
            <StepImage>
              <img src={step3} alt="Cofidis Solicitud de financiación" />
            </StepImage>
            <StepCaption>
              Completa la solicitud de financiación online con tus datos y obtén una respuesta
              inmediata
            </StepCaption>
          </Step>
          <Step>
            <StepImage>
              <img src={step4} alt="Colicitud aprovada" />
            </StepImage>
            <StepCaption>
              Con tu solicitud aprobada, el importe de tu compra se dividirá en 4 cuotas que se
              cargarán en tu tarjeta
            </StepCaption>
          </Step>
        </Steps>
        <Details>
          <h3>Preguntas frecuentes</h3>
          <dl>
            <dt>¿Cuánto tiempo necesitaré?</dt>
            <dd>
              Normalmente menos de 3 minutos. Tan sólo completa tu solicitud online y adjunta la
              foto de tu DNI/NIE.
            </dd>
            <dt>*¿Qué tarjetas puedo usar?</dt>
            <dd>
              {' '}
              Cofidis acepta cualquier tarjeta de crédito/débito excepto American Express, tarjetas
              extranjeras, virtuales.
            </dd>
            <dt>¿Puedo utilizar 4xcard para todos los productos?</dt>
            <dd>
              4xcard no está disponible para cestas que contengan cheques regalo, productos en
              preventa o productos sin stock.
            </dd>
            <dt>¿Cuándo se realizarán los cargos en mi tarjeta con 4xcard?</dt>
            <dd>
              La primera cuota será cargada el día después que el último producto de tu pedido es
              enviado. Posteriormente se realizarán los cargos a día+32, día+63 y día+90 desde el
              día que se envió este último producto de tu pedido.
            </dd>
            <dt>¿Puedo devolver los productos si los compro con 4xcard?</dt>
            <dd>
              La política de devoluciones se aplica a todas las compras. Si recibes un reembolso
              completo, se te rembolsará la cantidad que ya has pagado en base a las condiciones de
              compra. En el caso de un reembolso parcial, el importe total adeudado se modificará y
              las cuotas se volverán a calcular en consecuencia.
            </dd>
          </dl>
          <Legal>
            <p>
              Financiación ofrecida y sujeta a la aprobación de Cofidis, S.A. Sucursal en España
              para importes de 75€ a 1000€ en 4 cuotas. Comisión de apertura entre el 0 y el 3,5%
              del importe financiado. La TAE varía en función del importe financiado y el de la
              comisión de apertura.
            </p>
            <p>
              <strong>
                *Ejemplo de financiación para importe/PVP 75 €: 4 cuotas de 19,21 €. Importe total
                adeudado/importe total del crédito/precio total a plazos: 76,84 €. Comisión de
                apertura financiada/coste total del préstamo: 1,84 € (2,50%). TIN 0 %, TAE 21,10%.
              </strong>
            </p>
            <p>
              <strong>
                *Ejemplo de financiación para importe/PVP 1000 €: 4 cuotas de 256,25 €. Importe
                total adeudado/importe total del crédito/precio total a plazos: 1.025 €. Comisión de
                apertura financiada/coste total del préstamo: 25 € (2,50%). TIN 0 %, TAE 21,54%.
              </strong>
            </p>
            <p>
              Fecha de financiación del ejemplo representativo 01/01/2021 y primera amortización
              02/01/2021, segunda 02/02/2021, tercera 05/03/2021 y cuarta 01/04/2021. Sistema de
              amortización francés. Para otros importes y/o plazos, consulte condiciones de
              financiación en la solicitud de financiación.
            </p>
          </Legal>
        </Details>
      </Content>
    </>
  );
};

export default Page;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "cofidis"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
